import { useCallback, useMemo } from 'react'
import { useLocation } from '@reach/router'

import { getIsSsg } from '../../helpers/utils'

export type AnalyticsEventContext = {
  context_anonymous_id: string | null
  context_locale: string | null
  context_page_path: string | null
  context_page_referrer: string | null
  context_page_search: string | null
  context_page_title: string | null
  context_page_url: string | null
  context_user_agent: string | null
}

export const useComposeAnalyticsEventContext = () => {
  const location = useLocation()

  const segmentAnonymousId = useMemo(() => {
    // This is to prevent SSG errors
    if (getIsSsg()) {
      return null
    }
    return window.analytics?.user?.()?.anonymousId?.()
  }, [])

  // Using combination of Reach Router's useLocation for location details
  // and useCallback for window.document details to ensure correct
  // version of context used in the event of a page change
  const composeAnalyticsEventContext = useCallback((): AnalyticsEventContext => {
    // This is to prevent SSG errors
    if (getIsSsg()) {
      return {
        context_anonymous_id: segmentAnonymousId,
        context_locale: null,
        context_page_path: null,
        context_page_referrer: null,
        context_page_search: null,
        context_page_title: null,
        context_page_url: null,
        context_user_agent: null,
      }
    }
    return {
      context_anonymous_id: segmentAnonymousId,
      context_locale: window.navigator.language,
      context_page_path: location.pathname,
      context_page_referrer: window.document.referrer,
      context_page_search: location.search,
      context_page_title: window.document.title,
      context_page_url: location.href,
      context_user_agent: window.navigator.userAgent,
    }
  }, [segmentAnonymousId, location])

  return composeAnalyticsEventContext
}
