import React from 'react'
import styled from '@emotion/styled'

const superscriptMarks = new RegExp(/[®†‡™◊✪]/)

const SuperscriptMark = styled.sup`
  display: inline-block;
  opacity: 90%;
`

const IS_LETTER = new RegExp(/[a-zA-Z]/)
const IS_LETTER_PUNCTUATION = new RegExp(/[a-zA-Z][,.!?]/)
const IS_NUMBER_LETTER = new RegExp(/[0-9][a-zA-Z]/)

const markStrings = (child: React.ReactNode | React.ReactChildren) => {
  if (typeof child !== 'string') return child
  let lastChar = ' ',
    twoCharAgo = ' ',
    lastCharWasSuper = false

  const stringArr = child.split('')

  return stringArr
    .map((char, index) => {
      // 1 if char is a character in our superscript array, it should always be superscript
      // 2 if char is a number which immediately follows a letter it should be superscript
      // 2a unless it is also immediately followed by a letter (first half of postal code)
      // 2b unless it goes NUMBER-LETTER-NUMBER (second half of postal code)
      // 3 if char is a number which follow a punctuation mark that is preceeded by a letter it should be superscript (i.e word!1 => true 1.3 => false)
      // 4 if char is a number which follows a character that was superscript, it should also be superscript.
      const nextChar = stringArr[index + 1] || ' '
      const shouldBeSuperscript =
        superscriptMarks.test(char) ||
        (!isNaN(parseInt(char)) &&
          ((IS_LETTER.test(lastChar) &&
            !IS_LETTER.test(nextChar) &&
            !IS_NUMBER_LETTER.test(`${twoCharAgo}${lastChar}`)) ||
            IS_LETTER_PUNCTUATION.test(`${twoCharAgo}${lastChar}`) ||
            lastCharWasSuper))

      const shouldNextCharBeSuperscript =
        superscriptMarks.test(nextChar) ||
        (!isNaN(parseInt(nextChar)) &&
          ((IS_LETTER.test(char) && !IS_NUMBER_LETTER.test(`${lastChar}`)) ||
            IS_LETTER_PUNCTUATION.test(`${lastChar}`) ||
            shouldBeSuperscript))

      twoCharAgo = lastChar
      lastChar = char
      lastCharWasSuper = shouldBeSuperscript
      if (shouldBeSuperscript)
        return <SuperscriptMark key={Math.random()}>{char}</SuperscriptMark>

      return shouldNextCharBeSuperscript ? char.trim() : char
    })
    .reduce((acc: React.ReactNode[], current) => {
      const index = acc.length - 1
      if (typeof current === 'string' && typeof acc[index] === 'string') {
        acc[index] += current
      } else acc.push(current)
      return acc
    }, [])
}

export const Superscript: React.FC = ({ children }) =>
  React.useMemo(() => {
    return (
      <>
        {Array.isArray(children)
          ? children.map(child => markStrings(child))
          : markStrings(children)}
      </>
    )
  }, [children])
