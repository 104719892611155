import React, { PropsWithChildren } from 'react'
import LibraryMarkdown from 'markdown-to-jsx'
import { BaseArg } from 'facepaint'

import { Heading, Paragraph, List, ListElement } from './index'
import { Link } from '../Link'
import { Superscript } from './Superscript'
import { IWithStyleOverrides } from '../commonTypes'

interface IProps {
  customStyles?: {
    'heading-1'?: BaseArg
    'heading-2'?: BaseArg
    'heading-3'?: BaseArg
    'heading-4'?: BaseArg
    'heading-5'?: BaseArg
    link?: BaseArg
    paragraph?: BaseArg
    list?: BaseArg
    listElement?: BaseArg
  }
  withSuperscript?: boolean
  children: string
  contentfulId: string | null
  typeName: string | null
}

interface ILinkRenderer {
  href: string
  contentfulId: string | null
  typeName: string | null
}

const LinkRenderer: React.FC<ILinkRenderer & IWithStyleOverrides> = ({
  href,
  styleOverrides,
  contentfulId,
  typeName,
  ...props
}) => {
  return (
    <Link
      to={href}
      textStyles={styleOverrides}
      {...props}
      contentfulId={contentfulId}
      typeName={typeName}
    />
  )
}

const componentWithSuperscript = <Props extends Record<string, any>>(
  Component: React.FC<Props>,
): React.FC<Props> => {
  return (props: PropsWithChildren<Props>) => (
    <Component {...props}>
      <Superscript>{props.children}</Superscript>
    </Component>
  )
}

export const Markdown: React.FC<IProps> = ({
  customStyles,
  children,
  withSuperscript,
  contentfulId,
  typeName,
}) => {
  return (
    <LibraryMarkdown
      options={{
        forceBlock: true,
        overrides: {
          h1: {
            component: withSuperscript
              ? componentWithSuperscript(Heading)
              : Heading,
            props: {
              as: 'h1',
              styleOverrides: customStyles?.['heading-1'],
            },
          },
          h2: {
            component: withSuperscript
              ? componentWithSuperscript(Heading)
              : Heading,
            props: {
              as: 'h2',
              styleOverrides: customStyles?.['heading-2'],
            },
          },
          h3: {
            component: withSuperscript
              ? componentWithSuperscript(Heading)
              : Heading,
            props: {
              as: 'h3',
              styleOverrides: customStyles?.['heading-3'],
            },
          },
          h4: {
            component: withSuperscript
              ? componentWithSuperscript(Heading)
              : Heading,
            props: {
              as: 'h4',
              styleOverrides: customStyles?.['heading-4'],
            },
          },
          h5: {
            component: withSuperscript
              ? componentWithSuperscript(Heading)
              : Heading,
            props: {
              as: 'h5',
              styleOverrides: customStyles?.['heading-5'],
            },
          },
          a: {
            component: withSuperscript
              ? componentWithSuperscript(LinkRenderer)
              : LinkRenderer,
            props: {
              styleOverrides: customStyles?.['link'],
              contentfulId: contentfulId,
              typeName: typeName,
            },
          },
          p: {
            component: withSuperscript
              ? componentWithSuperscript(Paragraph)
              : Paragraph,
            props: {
              styleOverrides: customStyles?.['paragraph'],
            },
          },
          ol: {
            component: List,
            props: {
              styleOverrides: customStyles?.list,
              ordered: true,
            },
          },
          ul: {
            component: List,
            props: {
              styleOverrides: customStyles?.list,
              ordered: false,
            },
          },
          li: {
            component: withSuperscript
              ? componentWithSuperscript(ListElement)
              : ListElement,
            props: {
              styleOverrides: customStyles?.['listElement'],
            },
          },
        },
      }}
    >
      {children}
    </LibraryMarkdown>
  )
}
