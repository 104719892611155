import React from 'react'
import styled from '@emotion/styled'
import facepaint from 'facepaint'

//import { THEME } from '../constants/enums'
import { Link } from './Link'
import { AnalyticsService } from '../services/analyticsService'
import { IWithStyleOverrides, ICypressProps } from './commonTypes'
import { useTheme } from '@emotion/react'
import { StyledComponentWithChildren } from './StyledComponent'
import { IThemeDefinition } from '../styles/theme'
import { ILinkCookieData } from '../graphql/modules/linkCookieData'

type ButtonStyles = 'PRIMARY' | 'SECONDARY' | 'TERTIARY'
type ButtonSizes = 'SMALL' | 'MEDIUM' | 'LARGE'

export interface IButtonProps {
  name?: string
  to?: string
  theme?: ButtonStyles
  size?: ButtonSizes
  onClick?: (e: React.MouseEvent) => void
  onKeyDown?: (e: React.KeyboardEvent<Element>) => void
  styleOverrides?: facepaint.Arg
  style?: facepaint.Arg
  id?: string
  disabled?: boolean
  ariaLabel?: string
  linkCookieData?: ILinkCookieData | null
  rel?: string
  contentfulId: string | null
  typeName: string | null
}

interface IWithColorTheme extends IWithStyleOverrides {
  colorTheme: ButtonStyles
  size: ButtonSizes
}

const USE_THEME_LOOKUP: Record<ButtonStyles, keyof IThemeDefinition> = {
  PRIMARY: 'button',
  SECONDARY: 'secondaryButton',
  TERTIARY: 'tertiaryButton',
}

const sizeVariants = {
  SMALL: {
    width: '160px',
    height: '40px',
    fontSize: '14px',
    borderRadius: '22px',
  },
  LARGE: {
    width: ['100%', '320px', '470px'],
    height: ['55px', '55px', '95px'],
    fontSize: ['18px', '', '28px'],
    borderRadius: ['29px', '', '48px'],
  },
}
const customLinkStyles = {
  padding: '1px 0px 2px',
  maxWidth: '100%',
}

const LinkContainer: React.FC<
  IWithColorTheme &
    ICypressProps &
    Pick<
      IButtonProps,
      'name' | 'onClick' | 'onKeyDown' | 'disabled' | 'styleOverrides'
    >
> = ({ colorTheme, size, styleOverrides, ...props }) => {
  const themeStyles = useTheme()[USE_THEME_LOOKUP[colorTheme ?? 'PRIMARY']]
  const sizeOverrides =
    size && size !== 'MEDIUM' ? sizeVariants[size] : undefined

  const Component = StyledComponentWithChildren(styled.div, themeStyles)
  return (
    <Component
      {...props}
      styleOverrides={{ ...sizeOverrides, ...styleOverrides }}
    />
  )
}

const DefaultButton: React.FC<
  IWithColorTheme &
    ICypressProps &
    Pick<
      IButtonProps,
      'name' | 'onClick' | 'onKeyDown' | 'disabled' | 'styleOverrides'
    >
> = ({ colorTheme, size, styleOverrides, ...props }) => {
  const themeStyles = useTheme()[USE_THEME_LOOKUP[colorTheme ?? 'PRIMARY']]
  const sizeOverrides =
    size && size !== 'MEDIUM' ? sizeVariants[size] : undefined

  const Component = StyledComponentWithChildren<HTMLButtonElement>(
    styled.button,
    themeStyles,
  )
  return (
    <Component
      {...props}
      styleOverrides={{ ...sizeOverrides, ...styleOverrides }}
    />
  )
}

const ButtonComponent: React.FC<IButtonProps & ICypressProps> = ({
  name,
  theme = 'PRIMARY',
  size = 'MEDIUM',
  onClick,
  onKeyDown,
  styleOverrides = {},
  disabled,
  children,
  rel,
  ...otherButtonProps
}) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      AnalyticsService.sendClickAnalyticsInfo({ ctaName: name })
      if (typeof onClick === 'function') {
        onClick(e)
      }
    },
    [name, onClick],
  )

  return (
    <DefaultButton
      name={name}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      colorTheme={theme}
      size={size}
      styleOverrides={styleOverrides}
      disabled={disabled}
      {...otherButtonProps}
    >
      {children}
    </DefaultButton>
  )
}

/**
 * @description This is the external component, which decides between rendering
 * a Button or a Link, depending on the type of props passed to it.
 * For the pure Button component, please @see ButtonComponent
 */
export const Button: React.FC<IButtonProps & ICypressProps> = ({
  to = '',
  name,
  theme = 'PRIMARY',
  size = 'MEDIUM',
  onClick,
  onKeyDown,
  linkCookieData,
  styleOverrides = {},
  children,
  style = {},
  ariaLabel = '',
  rel,
  contentfulId,
  typeName,
  ...otherButtonProps
}) => {
  return to ? (
    <Link
      to={to}
      name={name}
      styleOverrides={{ ...customLinkStyles, ...style }}
      ariaLabel={ariaLabel}
      linkCookieData={linkCookieData}
      rel={rel}
      contentfulId={contentfulId}
      typeName={typeName}
      {...otherButtonProps}
    >
      <LinkContainer
        colorTheme={theme}
        size={size}
        styleOverrides={styleOverrides}
      >
        {children}
      </LinkContainer>
    </Link>
  ) : (
    <ButtonComponent
      name={name}
      id={name}
      onClick={onClick}
      onKeyDown={onKeyDown}
      theme={theme}
      size={size}
      styleOverrides={styleOverrides}
      aria-label={ariaLabel}
      rel={rel}
      contentfulId={contentfulId}
      typeName={typeName}
      {...otherButtonProps}
    >
      {children}
    </ButtonComponent>
  )
}
