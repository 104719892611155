import { useCallback } from 'react'
import { AnalyticsEvents } from '../../types/analytics/analyticsEvents.enum'
import { AnalyticsEventProperties, GTMEventProperties } from '../../types/analytics/analyticsEventProperties'
import { AnalyticsEventContext, useComposeAnalyticsEventContext } from './useComposeAnalyticsEventContext'

export const useSendGTMEvent = <TEventProperties extends AnalyticsEventProperties>() => {
  const composeAnalyticsEventContext = useComposeAnalyticsEventContext()

  const sendGTMEvent = useCallback(
    (eventName: AnalyticsEvents, eventProperties: TEventProperties) => {
      if (window?.dataLayer?.push) {
        const analyticsEventContext = composeAnalyticsEventContext()
        const event: GTMEventProperties<TEventProperties> & AnalyticsEventContext = {
          event: eventName,
          ...eventProperties,
          ...analyticsEventContext,
        }
        window.dataLayer.push(event)
      }
    },
    [composeAnalyticsEventContext],
  )

  return sendGTMEvent
}
