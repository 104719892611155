import React from 'react'
import mq from '../styles/breakpoints'
import facepaint from 'facepaint'
import { CreateStyledComponent, StyledComponent } from '@emotion/styled'
import { IWithStyleOverrides, IWithCustomStyles } from './commonTypes'
import { Theme } from '@emotion/react'

export const StyledComponentWithDefaultProps = (
  component: CreateStyledComponent<
    {
      theme?: Theme | undefined
      as?: React.ElementType<any> | undefined
    } & IWithStyleOverrides
  >,
  defaultStyles: facepaint.BaseArg
): StyledComponent<IWithStyleOverrides> => {
  return component(({ styleOverrides }: IWithStyleOverrides) =>
    mq({
      ...defaultStyles,
      ...styleOverrides,
    })
  )
}

/**
 * @description Higher-Order-Function to generate components with default styles injected that supports children
 * @example const Heading = StyledComponentWithChildren(styled.h1, { fontSize: '11px' });
 * @usage <Heading>This is the Title</Heading>
 * @todo deprecate the usage of the "text" prop instead of children
 */
export const StyledComponentWithChildren = <
  UsedHTMLElement extends HTMLElement
>(
  component: CreateStyledComponent<
    {
      theme?: Theme | undefined
      as?: React.ElementType | undefined
    } & IWithStyleOverrides
  >,
  defaultStyles: facepaint.BaseArg
): ((
  props: {
    children?: React.ReactNode
  } & IWithStyleOverrides &
    React.HTMLProps<UsedHTMLElement>
) => JSX.Element | null) => {
  const Component = StyledComponentWithDefaultProps(component, defaultStyles)
  return ({ styleOverrides, children, ...otherProps }) => {
    if (!children) return null
    return (
      <Component styleOverrides={styleOverrides} {...otherProps}>
        {children}
      </Component>
    )
  }
}

export default StyledComponentWithDefaultProps
