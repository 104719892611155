export * from './enums'

export const BULLET_ICON = '•'
export const GCLID = 'gclid'
export const HTTP_PREFIX = 'http'
export const VISIT_URL = 'visit_url'
export const UTM_MEDIUM = 'utm_medium'
export const UTM_SOURCE = 'utm_source'
export const UTM_CAMPAIGN = 'utm_campaign'
export const UTM_CONTENT = 'utm_content'
export const UTM_TERM = 'utm_term'
export const BWLL_MKT_PRODUCT_INTEREST = 'bwll_mkt_product_interest'
export const PRIMARY_THEME = 'primary'
export const COLON = ':'
export const WILDCARD = '*'
export const EMAIL_SERVICE_URL = 'https://borrowellhelp.zendesk.com/api/v2/requests.json'
export const ZENDESK_CATEGORY_FIELD_ID = 360033052551
export const ENTER_KEY_CODE = 13
export const SPACE_KEY_CODE = 32
export const METADATA = {
  BORROWELL_URL: 'https://borrowell.com',
  BORROWELL_TITLE: 'Borrowell™',
  BORROWELL_AUTHOR: 'Borrowell Inc.',
  BORROWELL_DESCRIPTION: 'Borrowell helps Canadians make great decisions about credit.',
  BORROWELL_KEYWORDS:
    'equifax free report, credit rating canada, how to check credit score, annual credit report canada',
  BORROWELL_LANGUAGE: 'en',
  BORROWELL_IMAGE: `https://images.ctfassets.net/ufihiutbamyi/1BHRWucOgmIjrXfwVrnQkC/eca0fe8c630fb619f9ad7840a7fda04f/credit-score-dial-logo-phone.png?w=1440&q=100`,
  BLOG_TITLE: 'The Well- Personal Finance Blog',
  BLOG_DESCRIPTION:
    'Get tips, tools and resources to improve your financial health and feel in control and optimistic about your future.',
  BLOG_KEYWORDS: 'Borrowell blog, Personal Finance, Credit Score',
} as const
export const HEADER_HEIGHT_VALUES = {
  MOBILE: 70,
  TABLET: 70,
  DESKTOP: 115,
}
