import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'
import { AnalyticsService } from '../services/analyticsService'
import { APP_INSIGHTS_CUSTOM_DIMENSIONS } from '../services/appInsightsService'
import { Link } from './Link'
import { Markdown } from './typography'

import CloseIcon from '../images/navbar-close.svg'
import { IGenericContent } from '../graphql/sections/genericContent'

interface ISnackBarQuery {
  contentfulGenericContent?: IGenericContent & { updatedAt: string }
}

interface ISnackContentData {
  name: string
  text: string
  link: {
    url: string
    label: string
  }
  ariaLabel: string
  animated: boolean
  contentful_id: string
  __typeName: string
}

interface ISnackBar extends ISnackContentData {
  updatedAt: string
  shouldStartHidden?: boolean
}

const ANIMATION_DURATION = 400 + 2000
const DELAY_DURATION = (2000 / ANIMATION_DURATION) * 100

const Container = styled.div<{ isVisible: boolean; animated: boolean }>(
  ({ isVisible, animated }) => `
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 0 10px 20px;
    background-color: ${COLORS.YELLOW['050']};
    box-shadow: 0 -3px 6px 0 rgba(69, 56, 178, 0.15);
    opacity: ${isVisible ? 1 : 0};
    display: flex;
    flex-direction: row;
    align-items: center;
    animation-name: ${isVisible ? 'fadeInOpacity' : 'fadeOutOpacity'};
    animation-timing-function: ease-in;
    animation-duration: ${animated ? ANIMATION_DURATION : 0}ms;
    z-index: 1;

    @keyframes fadeInOpacity {
      0% {
       // opacity: 0;
       bottom:-68px;
      }
      ${DELAY_DURATION}% {
          // opacity: 0;
          bottom:-68px;
        }
        100% {
          // opacity: 1;
          bottom:0;
        }
      }
      
    @keyframes fadeOutOpacity {
      0% {
        opacity: 1;
        bottom: 0px;
      }
      ${100 - DELAY_DURATION}%{
        opacity: 1;
        bottom: -68px;
      }
      100% {
        opacity: 1;
        bottom: -68px;
      }
    }
  `,
)

const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const DismissIcon = styled.img`
  ${mq({
    cursor: 'pointer',
    margin: 0,
    width: '48px',
    height: '48px',
    marginBottom: 0,
    marginRight: [0, '10px', '30px'],
  })}
`

const customFontSize = ['14px', '14px', '16px']

const customParagraphStyles = {
  margin: 0,
  color: COLORS.NEUTRAL.COOL['900'],
  fontSize: customFontSize,
}
const customLinksMarkdownStyles = {
  margin: 0,
  fontSize: customFontSize,
}

const customLinkStyles = {
  margin: '0 0 0 5px',
  fontSize: customFontSize,
}

export const SnackBar: React.FC<ISnackBar> = ({
  text,
  link,
  updatedAt,
  name,
  ariaLabel,
  shouldStartHidden = true,
  animated,
  contentful_id,
  __typeName,
}) => {
  const [isVisible, setIsVisible] = React.useState(true)
  const [isMounted, setIsMounted] = React.useState(true)
  const [hasBeenDismissed, setHasBeenDismissed] =
    React.useState(shouldStartHidden)

  const url = link.url
  const linkText = link.label

  const sessionStorageKey = `bw-snack-${updatedAt}-dismissed`

  const dismissSnack = () => {
    setIsVisible(false)
    AnalyticsService.trackCustomEvent({
      eventName: 'dismiss-snack',
      properties: {
        [APP_INSIGHTS_CUSTOM_DIMENSIONS.EVENT_LABEL]: name,
      },
    })
  }

  const navigateToLink = () => {
    setIsVisible(false)
    AnalyticsService.trackCustomEvent({
      eventName: 'click-snack-link',
      properties: {
        [APP_INSIGHTS_CUSTOM_DIMENSIONS.EVENT_LABEL]: name,
        [APP_INSIGHTS_CUSTOM_DIMENSIONS.PAGE_PATH]: url,
      },
    })
  }

  // Grabs the information about whether the user has dismissed the snack bar before in this session
  React.useEffect(() => {
    setHasBeenDismissed(!!sessionStorage.getItem(sessionStorageKey))
  }, [sessionStorageKey])

  // Defers the unmount to after the animation completes
  React.useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setIsMounted(false)
        sessionStorage.setItem(sessionStorageKey, 'true')
      }, ANIMATION_DURATION)
    }
  }, [isVisible, sessionStorageKey])

  return (
    <>
      {!hasBeenDismissed && isMounted && (
        <Container isVisible={isVisible} animated={animated}>
          <Content>
            <Markdown
              withSuperscript
              customStyles={{
                paragraph: customParagraphStyles,
                link: customLinksMarkdownStyles,
              }}
              contentfulId={contentful_id}
              typeName={__typeName}
            >
              {text}
            </Markdown>
            {url && linkText && (
              <Link
                to={url}
                onClick={navigateToLink}
                text={linkText}
                textStyles={customLinkStyles}
                contentfulId={contentful_id}
                typeName={__typeName}
              />
            )}
          </Content>
          <DismissIcon
            src={CloseIcon}
            alt="dismiss"
            aria-label={ariaLabel}
            onClick={dismissSnack}
            role="button"
            tabIndex={0}
          />
        </Container>
      )}
    </>
  )
}

const SnackBarSection: React.FC = () => {
  const { contentfulGenericContent: queryData } =
    useStaticQuery<ISnackBarQuery>(graphql`
      query SnackQuery {
        contentfulGenericContent(
          contentful_id: { eq: "6q2xHzbrDWMpTBkkYDphvS" }
        ) {
          ...GenericContentFragment
          updatedAt(formatString: "X")
          contentful_id
          __typename
        }
      }
    `)

  if (!queryData) return null

  const data = JSON.parse(queryData.data.internal.content)
    .componentData as ISnackContentData

  const { name, link, text, ariaLabel, animated, contentful_id, __typeName } =
    data

  return (
    <SnackBar
      text={text}
      link={link}
      updatedAt={queryData.updatedAt}
      name={name}
      ariaLabel={ariaLabel}
      animated={animated}
      contentful_id={contentful_id}
      __typeName={__typeName}
    />
  )
}

export default SnackBarSection
