import React, { useReducer } from 'react'

export interface IFAQData {
  [question: string]: string
}

interface IFAQInput {
  question: string
  answer: string
}

export interface IArticleData {
  headline: string
  datePublished: string
  image: string | string[]
  author?: IPerson | IPerson[]
}

export interface IPerson {
  name: string
  url?: string
  jobTitle?: string
  description?: string
}

interface IDataContext {
  faqData?: IFAQData
  articleData?: IArticleData
}

type ReducerAction =
  | {
      type: 'faqData'
      data: IFAQInput[]
    }
  | { type: 'articleData'; data: IArticleData }

const SEODataContext = React.createContext<IDataContext>({})
const SEOFunctionsContext = React.createContext<React.Dispatch<ReducerAction>>(
  {} as React.Dispatch<ReducerAction>
)

const structuredDataReducer = (
  state: IDataContext,
  { type, data }: ReducerAction
): IDataContext => {
  switch (type) {
    case 'faqData': {
      const faqData = (data as IFAQInput[]).reduce(
        (acc, { question, answer }) => {
          acc[question] = answer
          return acc
        },
        { ...state.faqData } as IFAQData
      )
      return { ...state, faqData }
    }
    case 'articleData': {
      return { ...state, articleData: data as IArticleData }
    }
    default:
      return state
  }
}

const SEOProvider: React.FC = ({ children }) => {
  const [structuredData, structuredDataDispatch] = useReducer(
    structuredDataReducer,
    {}
  )

  return (
    <SEODataContext.Provider value={structuredData}>
      <SEOFunctionsContext.Provider value={structuredDataDispatch}>
        {children}
      </SEOFunctionsContext.Provider>
    </SEODataContext.Provider>
  )
}

const useSEOData = (): IDataContext => {
  const context = React.useContext(SEODataContext)
  return context ? context : {}
}
const useSEOFunctions = (): React.Dispatch<ReducerAction> =>
  React.useContext(SEOFunctionsContext)

export { SEOProvider, useSEOData, useSEOFunctions }
