import React from 'react'
import styled from '@emotion/styled'

import { useTheme } from '@emotion/react'
import { StyledComponentWithChildren } from '../StyledComponent'
import { IWithStyleOverrides } from '../commonTypes'

interface IListProps extends IWithStyleOverrides {
  ordered?: boolean
}

export const List: React.FC<IListProps> = ({ ordered = true, ...props }) => {
  const styles = useTheme().list
  const Component = StyledComponentWithChildren(
    styled[ordered ? 'ol' : 'ul'],
    styles
  )

  return <Component {...props} />
}
