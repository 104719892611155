import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { LogLevel, getIsSsg, logger } from '../helpers/utils'

export interface IAnonymousIdContext {
  anonymousId: string | null
  isTimedOut: boolean
}

const getAnonymousId = () => {
  return getIsSsg() ? null : window?.analytics?.user?.()?.anonymousId() ?? null
}

const defaultContextState: IAnonymousIdContext = { anonymousId: getAnonymousId(), isTimedOut: false }

export const AnonymousIdContext = createContext<IAnonymousIdContext>(defaultContextState)

export const useAnonymousIdContext = () => {
  const context = useContext(AnonymousIdContext)
  if (!context) logger('AnonymousIdContext is being used outside of a context provider', LogLevel.ERROR)
  return context
}

const ANONYMOUS_ID_INITIALIZATION_TIMEOUT = 500 // 0.5 seconds
export const AnonymousIdContextProvider: React.FC = ({ children }) => {
  const [anonymousId, setAnonymousId] = useState<string | null>(getAnonymousId())
  const [isTimedOut, setIsTimedOut] = useState<boolean>(false)

  useEffect(() => {
    if (getIsSsg()) return

    let isCancelled = false
    const onSegmentReady = () => {
      if (isCancelled) return
      const newAnonymousId = getAnonymousId()
      setAnonymousId(newAnonymousId)
    }

    // on Segment ready, get the anonymous ID
    window.analytics?.ready(onSegmentReady)

    return () => {
      isCancelled = true
    }
  }, [])

  useEffect(() => {
    // If there is an anonymous ID, do not wait for the timeout
    if (anonymousId) {
      return
    }

    const timeoutId = setTimeout(() => {
      setIsTimedOut(true)
    }, ANONYMOUS_ID_INITIALIZATION_TIMEOUT)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [anonymousId])

  const anonymousIdContext = useMemo(
    () => ({ anonymousId: isTimedOut ? 'anonymous' : anonymousId, isTimedOut }),
    [anonymousId, isTimedOut],
  )

  return <AnonymousIdContext.Provider value={anonymousIdContext}>{children}</AnonymousIdContext.Provider>
}
