import './src/styles/reset.css'

import { ThemeProvider } from '@emotion/react'
import { navigate } from 'gatsby'
import React from 'react'
import ReactGA from 'react-ga'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import Layout from './src/components/Layout'
import { AnonymousIdContextProvider } from './src/context/AnonymousIdContextProvider'
import { PageContextProvider } from './src/context/PageContextProvider'
import { PathnameProvider } from './src/context/PathnameProvider'
import { SplitProvider } from './src/context/SplitProvider'
import { SEOProvider } from './src/context/useSEO'
import { isProductionEnvironment } from './src/helpers/utils'
import { AnalyticsService } from './src/services/analyticsService'
import { paramsService } from './src/services/paramsService'
import { baseTheme } from './src/styles/theme'

export const onClientEntry = () => {
  initGoogleAnalytics()
  const appInsightsInstrumentationKey = process.env.APP_INSIGHTS_INSTRUMENTATION_KEY
  if (appInsightsInstrumentationKey) AnalyticsService.init(appInsightsInstrumentationKey)
  paramsService.init(window.location.href)
}

export const onRouteUpdate = ({ location }) => {
  const sanitizedUrl = sanitizeUrl(location.pathname)
  AnalyticsService.trackPageView({ name: sanitizedUrl })
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={baseTheme}>
      <SEOProvider>
        <HelmetProvider>
          <AnonymousIdContextProvider>
            <SplitProvider>
              <PageContextProvider>{element}</PageContextProvider>
            </SplitProvider>
          </AnonymousIdContextProvider>
        </HelmetProvider>
      </SEOProvider>
    </ThemeProvider>
  )
}

// Component for running page level experiments, gets rerendered on each navigation event
const PageExperiments = ({ pathname }) => {
  return null
}
export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  const sectionWithRedirect = props?.data?.contentfulPage?.sections.find(
    section => section.__typename === 'ContentfulMetadata' && section.redirect === true,
  )

  if (sectionWithRedirect) {
    const redirectTo = sectionWithRedirect.redirectUrl ?? '/'
    redirectTo.startsWith('https://') ? (window.location = redirectTo) : navigate(redirectTo, { replace: true })
  }

  const SHOULD_NOT_INDEX = !isProductionEnvironment() || String(process.env.GENERATE_METADATA).toLowerCase() !== 'true'

  const pathname = props.path

  return sectionWithRedirect ? (
    <Helmet>
      <link rel="canonical" href={sectionWithRedirect.canonicalLink} />
    </Helmet>
  ) : (
    <>
      <Helmet>{SHOULD_NOT_INDEX && <meta name="robots" content="noindex" />}</Helmet>
      <PathnameProvider pathname={pathname}>
        <PageExperiments pathname={pathname} />
        <Layout {...props}>{element}</Layout>
      </PathnameProvider>
    </>
  )
}

/**********************************
 *
 * @description private functions
 *
 *********************************/

function sanitizeUrl(originalUrl) {
  const LAST_SLASH_REGEX = /\/$/
  if (originalUrl.length === 1) {
    return originalUrl
  }
  return originalUrl.replace(LAST_SLASH_REGEX, '')
}

function initGoogleAnalytics() {
  if (process.env.GA_TOKEN) {
    ReactGA.initialize(process.env.GA_TOKEN)
  } else {
    console.error('Please configure your Google Analytics to Track Events!')
  }
}
