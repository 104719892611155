import React, { createContext, useContext, useMemo, useState } from 'react'
import { LogLevel, logger } from '../helpers/utils'

export interface IPageContext {
  pageContentfulId: string | null
  experiment: string | null
  treatmentRequested: string | null
  treatmentSelected: string | null
  pagePath: string | null
  pageUrl: string | null
}

interface IPageContextState {
  pageContext: IPageContext
  setPageContext: React.Dispatch<React.SetStateAction<IPageContext>>
}

const defaultContextValue: IPageContextState = {
  pageContext: {
    // To keep track of which version of page context are in the state.
    pageContentfulId: null,
    experiment: null,
    treatmentRequested: null,
    treatmentSelected: null,
    // To track the current page path and url that is not canonical link captured by Segment through context.
    pagePath: null,
    pageUrl: null,
  },
  setPageContext: () => {
    logger('setPageContext is not initialized yet', LogLevel.ERROR)
  },
}

const PageContext = createContext<IPageContextState>(defaultContextValue)

export const usePageContext = () => {
  const context = useContext(PageContext)
  if (!context) logger('PageContext is being used outside of a context provider', LogLevel.ERROR)
  return context
}

export const PageContextProvider: React.FC = ({ children }) => {
  const [pageContext, setPageContext] = useState<IPageContext>(defaultContextValue.pageContext)
  const contextValue = useMemo(() => ({ pageContext, setPageContext }), [pageContext, setPageContext])

  return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
}
