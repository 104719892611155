export enum THEME {
  PRIMARY_THEME = 'primary',
  SECONDARY_THEME = 'secondary',
}

export enum ProductInterests {
  CREDIT_BUILDER_LOAN = 'cb',
  FREE_CREDIT_SCORE = 'fcs', // default
}

export enum TypeNames {
  CONTENTFUL_BANNER = 'ContentfulBanner',
  CONTENTFUL_BLOG = 'ContentfulBlog',
  CONTENTFUL_BLOGS = 'ContentfulBlogs',
  CONTENTFUL_METADATA = 'ContentfulMetadata',
  CONTENTFUL_BLOG_CATALOG_WITH_CATEGORIES = 'ContentfulBlogCatalogWithCategories',
  CONTENTFUL_PAGE_VARIATION = 'ContentfulPageVariation',
}

export enum ContentfulPagePaths {
  ERROR_404 = '/error',
}
