import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import { StyledComponentWithChildren } from '../StyledComponent'
import { IWithStyleOverrides } from '../commonTypes'
import { useTheme } from '@emotion/react'

export const Paragraph: React.FC<IWithStyleOverrides> = props => {
  const styles = useTheme().paragraph
  const Component = StyledComponentWithChildren(styled.p, styles)
  return <Component {...props} />
}
