import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let AppInsightsInstance = null

export const APP_INSIGHTS_CUSTOM_DIMENSIONS = {
  EVENT_LABEL: 'EventLabel',
  PAGE_PATH: 'PagePath',
  MARKETING_SITE_GUID: 'MarketingSiteSessionId',
  AUTHENTICATED_SESSION_ID: 'AuthenticatedSessionId',
}

export default {
  init(instrumentationKey) {
    if (instrumentationKey) {
      AppInsightsInstance = new ApplicationInsights({
        config: { instrumentationKey },
      })
      AppInsightsInstance.loadAppInsights()
    } else {
      console.error('Please configure your App Insights to Track Events!')
    }
  },

  trackEvent(eventType, properties = {}) {
    AppInsightsInstance &&
      AppInsightsInstance.trackEvent({ name: eventType }, properties)
  },

  trackPageView(name, properties = {}) {
    AppInsightsInstance &&
      AppInsightsInstance.trackPageView({
        name,
        properties,
      })
  },

  getSessionId() {
    if (AppInsightsInstance) {
      return AppInsightsInstance.context.sessionManager.automaticSession.id
    }

    return null
  },
}
