import { COLORS, toRGBAString } from '@borrowell/bw-styles'
import facepaint from 'facepaint'

export interface IThemeDefinition {
  paragraph?: facepaint.BaseArg
  h1?: facepaint.BaseArg
  h2?: facepaint.BaseArg
  h3?: facepaint.BaseArg
  h4?: facepaint.BaseArg
  h5?: facepaint.BaseArg
  blockquote?: facepaint.BaseArg
  link?: facepaint.BaseArg
  secondaryLink?: facepaint.BaseArg
  button?: facepaint.BaseArg
  secondaryButton?: facepaint.BaseArg
  tertiaryButton?: facepaint.BaseArg
  hr?: facepaint.BaseArg
  list?: facepaint.BaseArg
  listItem?: facepaint.BaseArg
  input?: facepaint.BaseArg
  inputDark?: facepaint.BaseArg
  dropdownOption?: facepaint.BaseArg
}

const commonButtonStyles = {
  width: ['100%', '320px', '320px'],
  flex: 1,
  height: [undefined, '55px', '55px'],
  fontFamily: 'Lato',
  fontWeight: '900',
  fontSize: '18px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 55px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  borderRadius: '28px',
} as const

export const baseTheme: Required<IThemeDefinition> = {
  paragraph: {
    fontFamily: 'Lato',
    fontSize: ['16px', '18px', '18px'],
    lineHeight: ['1.5', '1.44', '1.44'],
    textAlign: ['left', 'center', 'center'],
    margin: ['25px 0 40px 0', '25px 0 50px 0', '25px 0 50px 0'],
    maxWidth: ['none', '528px', '855px'],
    color: COLORS.NEUTRAL.COOL['600'],
    a: {
      textDecoration: 'underline',
      textDecorationColor: COLORS.PURPLE['700'],
      '&:hover': {
        textDecorationColor: COLORS.PRIMARY['500'],
      },
    },
  },
  h1: {
    fontFamily: 'Merriweather',
    fontSize: ['40px', '55px', '55px'],
    fontWeight: '900',
    lineHeight: ['1.3', '1.2', '1.2'],
    textAlign: 'center',
    margin: '0',
    color: COLORS.NEUTRAL.COOL['900'],
  },
  h2: {
    fontFamily: 'Merriweather',
    fontSize: ['33px', '44px', '44px'],
    fontWeight: '900',
    lineHeight: ['1.33', '1.36', '1.36'],
    textAlign: 'center',
    margin: '0',
    color: COLORS.NEUTRAL.COOL['900'],
  },
  h3: {
    fontFamily: 'Merriweather',
    fontSize: ['28px', '35px', '35px'],
    fontWeight: '900',
    lineHeight: ['1.21', '1.43', '1.43'],
    textAlign: 'center',
    margin: '0',
    color: COLORS.NEUTRAL.COOL['900'],
  },
  h4: {
    fontFamily: 'Merriweather',
    fontSize: ['23px', '28px', '28px'],
    fontWeight: '900',
    lineHeight: ['1.48', '1.43', '1.43'],
    textAlign: 'center',
    margin: '0',
    color: COLORS.NEUTRAL.COOL['900'],
  },
  h5: {
    fontFamily: 'Lato',
    fontSize: ['19px', '22px', '22px'],
    fontWeight: '900',
    lineHeight: ['1.42', '1.45', '1.45'],
    textAlign: 'center',
    margin: '0',
    color: COLORS.NEUTRAL.COOL['900'],
  },
  blockquote: {
    fontFamily: 'Lato',
    fontSize: ['16px', '18px', '18px'],
    lineHeight: ['1.5', '1.44', '1.44'],
    textAlign: 'left',
    margin: '0',
    color: COLORS.NEUTRAL.COOL['600'],
  },
  link: {
    fontFamily: 'Lato',
    fontSize: ['16px', '18px', '18px'],
    lineHeight: ['1.5', '1.44', '1.44'],
    fontWeight: 'bold',
    color: COLORS.PURPLE['700'],
    ':hover,:active,:focus': {
      color: COLORS.PRIMARY['500'],
    },
  },
  secondaryLink: {
    fontFamily: 'Lato',
    fontSize: '14px',
    color: COLORS.WHITE,
    textDecoration: 'none',
    letterSpacing: '0.2px',
    ':hover': {
      color: COLORS.YELLOW['400'],
    },
  },
  button: {
    ...commonButtonStyles,
    color: COLORS.PURPLE['700'],
    borderStyle: 'none',
    backgroundColor: COLORS.YELLOW['400'],
    ':hover': {
      backgroundColor: COLORS.YELLOW['300'],
    },
  },
  secondaryButton: {
    ...commonButtonStyles,
    color: COLORS.PURPLE['700'],
    border: `2px solid ${COLORS.PURPLE['700']}`,
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: COLORS.PURPLE['050'],
    },
  },
  tertiaryButton: {
    ...commonButtonStyles,
    backgroundColor: 'transparent',
    border: `2px solid ${COLORS.WHITE}`,
    color: COLORS.WHITE,
    ':hover': {
      border: 'none',
      backgroundColor: COLORS.PURPLE['050'],
      color: COLORS.PURPLE['700'],
    },
    ':active': {
      border: 'none',
      color: COLORS.PURPLE['700'],
      backgroundColor: COLORS.WHITE,
    },
  },
  hr: {
    backgroundColor: `${COLORS.NEUTRAL.COOL['600']}`,
    height: '1px',
    width: '100%',
    border: 'none',
  },
  list: {
    alignSelf: 'start',
    alignText: 'left',
    marginBottom: '0',
    '& > li:last-of-type': {
      marginBottom: '20px',
    },
  },
  listItem: {
    fontFamily: 'Lato',
    fontSize: ['16px', '18px', '18px'],
    lineHeight: ['1.5', '1.44', '1.44'],
    textAlign: 'left',
    margin: '0',
    color: COLORS.NEUTRAL.COOL['600'],
  },
  input: {
    border: `1px solid ${COLORS.NEUTRAL.COOL['600']}`,
    borderRadius: '8px',
    color: COLORS.NEUTRAL.COOL['900'],
    fontSize: '18px',
    width: '100%',
    height: '54px',
    margin: '6px 0',
    padding: '0 15px',
    outline: 'none',
    '&:focus': {
      border: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
    },
    error: {
      border: `1px solid ${COLORS.RED['700']}`,
    },
  },
  inputDark: {
    border: 'none',
    borderRadius: '8px',
    color: COLORS.NEUTRAL.COOL['900'],
    fontSize: '18px',
    width: '100%',
    height: '54px',
    margin: '6px 0',
    padding: '15px',
    outline: 'none',
    error: {
      border: `1px solid ${COLORS.RED['700']}`,
    },
  },
  dropdownOption: {
    ...commonButtonStyles,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '0',
    border: 'none',
    borderLeft: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
    borderRight: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
    padding: '15px',
    cursor: 'pointer',
    backgroundColor: COLORS.WHITE,
    color: COLORS.NEUTRAL.COOL['900'],
    ':focus,:hover': {
      backgroundColor: toRGBAString(COLORS.PRIMARY['050'], 0.5),
    },
    ':first-of-type': {
      borderTop: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
    },
    ':last-of-type': {
      borderBottom: `1px solid ${COLORS.PRIMARY.DEFAULT}`,
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
}
