import React from 'react'
import styled from '@emotion/styled'
import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from 'gatsby-plugin-image'

import mq from '../styles/breakpoints'
import facepaint from 'facepaint'
import { IWithCustomStyles } from './commonTypes'

interface ICustomImageProps extends IWithCustomStyles {
  alt: string
  fluid?: IGatsbyImageData
  fixed?: IGatsbyImageData
  customImageStyles?: React.CSSProperties
  objectFit?: GatsbyImageProps['objectFit']
}

const defaultDimensions: React.CSSProperties = {
  width: '100px',
  height: '100px',
}

const ImageWrapper = styled.div<IWithCustomStyles>(({ customStyles }) =>
  mq({
    ...defaultDimensions,
    ...customStyles,
  }),
)

export const Image: React.FC<ICustomImageProps> = ({
  customStyles,
  customImageStyles,
  alt,
  fluid,
  fixed,
  objectFit,
}) =>
  React.useMemo(() => {
    const wrapperStyles: facepaint.BaseArg = {
      ...defaultDimensions,
      ...customStyles,
    }

    if (fluid) {
      return (
        <ImageWrapper customStyles={wrapperStyles}>
          <GatsbyImage
            image={fluid}
            alt={alt}
            imgStyle={customImageStyles}
            objectFit={objectFit}
          />
        </ImageWrapper>
      )
    }

    if (fixed) {
      // Can not use media queries for styling fixed images
      return (
        <GatsbyImage
          image={fixed}
          alt={alt}
          imgStyle={customImageStyles}
          objectFit={objectFit}
        />
      )
    }
    return null
  }, [customStyles, customImageStyles, alt, fluid, fixed])
