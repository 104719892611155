/* eslint-disable @typescript-eslint/no-use-before-define */
import ReactGA from 'react-ga'

import ApplicationInsightsService, { APP_INSIGHTS_CUSTOM_DIMENSIONS } from './appInsightsService'
import fb from './facebookService'
import { paramsService, IParams } from './paramsService'
import { MARKETING_SESSION_ID_COOKIE_KEY, AUTHENTICATED_SESSION_COOKIE_KEY } from './CookiesService'

export const AnalyticsService = {
  init: (applicationInsightsKey: string) => {
    ApplicationInsightsService.init(applicationInsightsKey)
  },

  sendClickAnalyticsInfo: ({ ctaName, url }: { ctaName?: string; url?: string }) => {
    const pagePath = url || getCurrentUrl()
    const customDimensions = injectBaseCustomDimensions({
      [APP_INSIGHTS_CUSTOM_DIMENSIONS.EVENT_LABEL]: ctaName,
      [APP_INSIGHTS_CUSTOM_DIMENSIONS.PAGE_PATH]: pagePath,
    })

    ApplicationInsightsService.trackEvent('button-click', customDimensions)

    ReactGA.event({
      category: 'link',
      action: 'click',
      label: ctaName,
    })

    if (ctaName && ctaName.toString().indexOf('CTA') > -1) {
      fb.trackViewContent()
    }
  },

  trackCustomEvent: ({ eventName, properties = {} }: { eventName?: string; properties?: object }) => {
    if (eventName) {
      const dimensions = injectBaseCustomDimensions(properties)
      ApplicationInsightsService.trackEvent(eventName, dimensions)
    }
  },

  trackPageView: ({ name, properties = {} }: { name: string; properties?: object }) => {
    const dimensions = injectBaseCustomDimensions(properties)
    ApplicationInsightsService.trackPageView(name, dimensions)

    ReactGA.pageview(name)
  },
}

// Private Functions

const getCurrentUrl = () => {
  if (window && window.location) {
    return window.location.href
  }
  return ''
}

export const injectBaseCustomDimensions = (customDimensions: object) => {
  const storedQueryParams = paramsService.getParams()
  return {
    ...customDimensions,
    ...transformCustomDimensions(storedQueryParams),
  }
}

export const transformCustomDimensions = (storedQueryParams: IParams): Record<string, string> => {
  const paramKeys: string[] = []
  const paramValues: string[] = []
  Object.entries(storedQueryParams).forEach(([key, value]) => {
    let appInsightsKey: string = key
    if (appInsightsKey === MARKETING_SESSION_ID_COOKIE_KEY)
      appInsightsKey = APP_INSIGHTS_CUSTOM_DIMENSIONS.MARKETING_SITE_GUID
    if (appInsightsKey === AUTHENTICATED_SESSION_COOKIE_KEY)
      appInsightsKey = APP_INSIGHTS_CUSTOM_DIMENSIONS.AUTHENTICATED_SESSION_ID
    paramKeys.push(appInsightsKey)
    paramValues.push(value)
  })

  return paramValues.reduce((params, value, index) => {
    if (value) {
      params[paramKeys[index]] = value
    }
    return params
  }, {} as Record<string, string>)
}
