import { useCallback } from 'react'
import { AnalyticsEvents } from '../../types/analytics/analyticsEvents.enum'
import { LinkClickedEventProperties } from '../../types/analytics/analyticsEventProperties'
import { useSendGTMEvent } from './useSendGTMEvent'
import { useSendSegmentEvent } from './useSendSegmentEvent'

export const useSendLinkClickedEvent = () => {
  const { sendSegmentTrackEvent } = useSendSegmentEvent<LinkClickedEventProperties>()
  const sendGTMEvent = useSendGTMEvent<LinkClickedEventProperties>()

  const sendLinkClickedEvent = useCallback(
    (linkClickedEventProperties: LinkClickedEventProperties) => {
      sendSegmentTrackEvent(AnalyticsEvents.LINK_CLICKED, linkClickedEventProperties)
      sendGTMEvent(AnalyticsEvents.LINK_CLICKED, linkClickedEventProperties)
    },
    [sendSegmentTrackEvent, sendGTMEvent],
  )

  return sendLinkClickedEvent
}
