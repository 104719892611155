import Cookies from 'js-cookie'

import { isDevelopment, getIsSsg } from '../helpers/utils'

export const MARKETING_SESSION_ID_COOKIE_KEY = 'bwll_mkt_session_id'
export const MARKETING_UID_COOKIE_KEY = 'bwll_mkt_uid'
export const AUTHENTICATED_SESSION_COOKIE_KEY = 'bwsid'

function getDefaultDomain(host: string) {
  if (host.includes('localhost')) return ''
  if (host.includes('bwll.net')) return 'bwll.net'
  return host
}

export const DEFAULT_COOKIE_ATTRIBUTES: Cookies.CookieAttributes = {
  // 1 Day
  expires: 1,
  domain: getDefaultDomain(getIsSsg() ? 'borrowell.com' : window.location.host),
  secure: !isDevelopment(),
  sameSite: 'lax',
}

export interface ISetCookie {
  key: string
  value: string
  cookieAttributes?: Cookies.CookieAttributes
}

export abstract class AbstractCookieService {
  abstract getCookie(key: string): string | undefined
  abstract setCookie(attributes: ISetCookie): string | undefined
  abstract removeCookie(key: string): void
}

export class CookieService extends AbstractCookieService {
  getCookie(key: string) {
    return Cookies.get(key)
  }

  setCookie({ key, value, cookieAttributes }: ISetCookie) {
    const attributes = { ...DEFAULT_COOKIE_ATTRIBUTES, ...cookieAttributes }
    this.removeCookie(key)
    return Cookies.set(key, value, attributes)
  }

  removeCookie(key: string) {
    return Cookies.remove(key)
  }
}

export const cookieService = new CookieService()
