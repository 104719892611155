import React from 'react'
import styled from '@emotion/styled'

import { StyledComponentWithChildren } from '../StyledComponent'
import { IWithStyleOverrides } from '../commonTypes'
import { useTheme } from '@emotion/react'

export const ListElement: React.FC<IWithStyleOverrides> = props => {
  const style = useTheme().listItem
  const Component = StyledComponentWithChildren(styled.li, style)
  return <Component {...props} />
}
