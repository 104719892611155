import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import React, { useEffect, useMemo } from 'react'

import { getIsSsg, logger, LogLevel } from '../helpers/utils'
import { initSplitRUMAgent } from '../services/experimentsService'
import { useAnonymousIdContext } from './AnonymousIdContextProvider'

const composeSdkConfig = (splitUserKey: string): SplitIO.IBrowserSettings => {
  const authorizationKey = process.env.GATSBY_SPLIT_IO_SDK_KEY ?? ''

  if (!splitUserKey) {
    logger('[Split] Anonymous ID does not exist.', LogLevel.ERROR)
  }

  if (!authorizationKey) {
    logger('[Split] Missing Split authorization key.', LogLevel.ERROR)
  }

  return {
    // See readme for setup - https://github.com/splitio/react-client#readme
    core: {
      authorizationKey,
      key: splitUserKey,
      trafficType: 'user',
    },
    storage: {
      type: 'LOCALSTORAGE',
      prefix: 'bwll',
    },
  }
}

// Using the SplitFactoryProvider component, MyApp component and all it's descendants will have access to the SDK functionality.
export const SplitProvider: React.ComponentType = ({ children }) => {
  // TODO: GROW-1976- Make "successful Segment script load" not a dependency to having Anonymous ID
  const { anonymousId } = useAnonymousIdContext()
  const splitUserKey: string = useMemo(() => (getIsSsg() ? 'ssg-user' : anonymousId ?? ''), [anonymousId])
  const sdkConfig: SplitIO.IBrowserSettings = useMemo(() => composeSdkConfig(splitUserKey), [splitUserKey])

  useEffect(() => {
    if (splitUserKey) {
      initSplitRUMAgent(splitUserKey)
    }
  }, [splitUserKey])

  return (
    <SplitFactoryProvider config={sdkConfig} updateOnSdkTimedout updateOnSdkUpdate>
      {children}
    </SplitFactoryProvider>
  )
}
