import React, { MouseEvent } from 'react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { Link as _Link } from 'gatsby'
import facepaint from 'facepaint'
import { useTheme } from '@emotion/react'

import mq from '../styles/breakpoints'
import { THEME } from '../constants/enums'
import { isHelpCentreLink, isInternalNavigationLink, isThirdPartyLink } from '../helpers/urlFunctions'
import { AnalyticsService } from '../services/analyticsService'
import { paramsService } from '../services/paramsService'
import { getIsSsg } from '../helpers/utils'
import { IWithStyleOverrides, ICypressProps } from './commonTypes'
import { StyledComponentWithChildren } from './StyledComponent'
import { ILinkCookieData } from '../graphql/modules/linkCookieData'
import { useSendLinkClickedEvent } from '../hooks/analytics/useSendLinkClickedEvent'

interface ILinkProps extends IWithStyleOverrides {
  to: string
  name?: string
  onClick?: (event: MouseEvent) => void
  alt?: string
  rel?: string
  theme?: THEME
  text?: string
  textStyles?: facepaint.BaseArg
  ariaLabel?: string
  linkCookieData?: ILinkCookieData | null
  contentfulId: string | null
  typeName: string | null
  withoutTextStyling?: boolean
}

interface ICustomAnchorProps extends IWithStyleOverrides {
  anchorId: string
  onClick?: () => void
  textStyles?: facepaint.BaseArg
  theme?: THEME
}

interface ILinkButtonProps extends IWithStyleOverrides {
  name?: string
  alt?: string
}

const LinkButton = styled('a', {
  shouldForwardProp: prop => isPropValid(prop),
})<ILinkButtonProps>(({ styleOverrides }) =>
  mq({
    textDecoration: 'none',
    ...styleOverrides,
  }),
)

const GatsbyLink = LinkButton.withComponent(_Link)

const Text: React.FC<{ colorTheme: THEME; textStyles?: facepaint.BaseArg }> = ({
  colorTheme,
  textStyles,
  ...props
}) => {
  const themeStyles = useTheme()[colorTheme === THEME.PRIMARY_THEME ? 'link' : 'secondaryLink']
  const Component = StyledComponentWithChildren(styled.span, themeStyles)
  return <Component styleOverrides={textStyles} {...props} />
}

export const AnchorLink: React.FC<ICustomAnchorProps & ICypressProps> = ({
  anchorId,
  children,
  onClick,
  textStyles,
  styleOverrides,
  'data-cy': testId,
  theme = THEME.PRIMARY_THEME,
}) => {
  const sendLinkClickedEvent = useSendLinkClickedEvent()

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()

    sendLinkClickedEvent({
      // TODO: Populate entry_id and contentful_type
      entry_id: 'N/A',
      contentful_type: 'N/A',
      link_to: `#${anchorId}`,
      link_text: anchorId,
    })

    if (onClick) onClick()
    else window.location.href = `${window.location.href.split('#')[0]}#${anchorId}`
  }
  return (
    <LinkButton
      href={`#${anchorId}`}
      onClick={handleClick}
      styleOverrides={styleOverrides}
      data-cy={testId}
      // TODO: Set data-entry-id value to contentful_id
      data-entry-id="N/A"
    >
      <Text colorTheme={theme} textStyles={textStyles}>
        {children}
      </Text>
    </LinkButton>
  )
}

export const Link: React.FC<ILinkProps & ICypressProps> = ({
  to = '',
  name,
  children,
  onClick,
  alt,
  rel,
  theme = THEME.PRIMARY_THEME,
  text,
  textStyles,
  styleOverrides,
  ariaLabel,
  linkCookieData,
  contentfulId,
  typeName,
  withoutTextStyling = false,
  ...otherLinkProps
}) => {
  const linkRef = React.useRef<any>(null)
  const sendLinkClickedEvent = useSendLinkClickedEvent()
  const isInternalNavigation = isInternalNavigationLink(to)

  const setProvidedCookieData = React.useCallback(() => {
    if (!linkCookieData) return

    paramsService.setParam(linkCookieData.cookieKey, linkCookieData.cookieValue)
  }, [linkCookieData])

  const handleClick = React.useCallback(
    (event: MouseEvent) => {
      // Set linkCookieData if provided
      setProvidedCookieData()

      // Reporting Analytics Events
      if (!isInternalNavigation) event.preventDefault()
      AnalyticsService.sendClickAnalyticsInfo({ ctaName: name, url: to })
      paramsService.renewParams()

      sendLinkClickedEvent({
        entry_id: contentfulId ?? 'N/A',
        contentful_type: typeName ?? 'N/A',
        link_to: to,
        link_text: (text || linkRef.current?.innerText) ?? 'N/A',
      })

      // Invoking Callback
      if (typeof onClick === 'function') {
        onClick(event)
      }

      let navigationTimeoutId: NodeJS.Timeout
      if (!isInternalNavigation && to && !getIsSsg()) {
        // Wait 300ms before navigating to ensure segment events have been sent first
        navigationTimeoutId = setTimeout(() => {
          if (isThirdPartyLink(to) || isHelpCentreLink(to)) {
            window.open(to, '_blank', 'noopener,noreferrer')
          } else {
            window.location.href = to
          }
        }, 300) as unknown as NodeJS.Timeout
      }
      return () => clearTimeout(navigationTimeoutId)
    },
    [
      to,
      name,
      onClick,
      sendLinkClickedEvent,
      isInternalNavigation,
      linkCookieData,
      contentfulId,
      typeName,
      text,
      children,
    ],
  )

  // TODO: GROW-1977 - Investigate on flickering caused by wrapping with Text  emotion+facepaint component.
  // Temporary fix for Images wrapped in Link to not flicker due to emotion+facepaint component.
  const LinkContent = withoutTextStyling ? (
    <>{children || text}</>
  ) : (
    <Text colorTheme={theme} textStyles={textStyles}>
      {children || text}
    </Text>
  )

  return isInternalNavigation ? (
    <GatsbyLink
      to={to}
      styleOverrides={styleOverrides}
      alt={alt}
      rel={rel}
      name={name}
      ref={linkRef}
      data-entry-id={contentfulId}
      {...otherLinkProps}
      onClick={handleClick}
      aria-label={ariaLabel}
    >
      {LinkContent}
    </GatsbyLink>
  ) : (
    <LinkButton
      href={to}
      name={name}
      ref={linkRef}
      onClick={handleClick}
      styleOverrides={styleOverrides}
      data-entry-id={contentfulId}
      {...otherLinkProps}
      alt={alt}
      rel={rel}
      aria-label={ariaLabel}
    >
      {LinkContent}
    </LinkButton>
  )
}
