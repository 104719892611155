import React, { useContext, createContext } from 'react'

const pathnameContext = createContext('/')

export const PathnameProvider: React.FC<{ pathname: string }> = ({
  pathname,
  children,
}) => {
  return (
    <pathnameContext.Provider value={pathname}>
      {children}
    </pathnameContext.Provider>
  )
}

export const usePathname = () => {
  const ctx = useContext(pathnameContext)
  if (!ctx)
    throw new Error(
      process.env.NODE_ENV === 'development'
        ? 'Make sure you have wrapped your page level component'
        : 'Something went wrong'
    )
  return ctx
}
