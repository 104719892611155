import facepaint from 'facepaint'

// As we are doing mobile first, mobile breakpoints are not needed
// but those are mobileMinWidth = 320px and mobileMaxWidth = 599

// Desktop maximum width will be 1440px.

const mobileMaxWidth = 599
const tabletMinWidth = 600
const tabletMaxWidth = 899
const desktopMinWidth = 900

export const mediaQueries = [
  `(max-width: ${mobileMaxWidth}px)`,
  `(min-width: ${tabletMinWidth}px) and (max-width: ${tabletMaxWidth}px)`,
  `(min-width: ${desktopMinWidth}px)`,
]

export const mq = facepaint(
  mediaQueries.slice(1).map(query => `@media ${query}`)
)

export default mq
