import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import { StyledComponentWithChildren } from '../StyledComponent'
import { useTheme } from '@emotion/react'
import { IWithStyleOverrides } from '../commonTypes'

type HeadingKeys = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

interface IHeadingProps extends IWithStyleOverrides {
  as?: HeadingKeys
  styledAs?: HeadingKeys
}

export const Heading: React.FC<IHeadingProps> = ({
  as = 'h1',
  styledAs,
  ...props
}) => {
  const styles = useTheme()[styledAs || as]
  const Component = StyledComponentWithChildren(styled[as], styles)
  return <Component {...props} />
}
