import { useCallback, useMemo } from 'react'
import { usePageContext } from '../../context/PageContextProvider'
import { useBwllMktSessionId } from '../../hooks/useBwllMktSessionId'
import { AnalyticsEventProperties } from '../../types/analytics/analyticsEventProperties'
import { AnalyticsEvents } from '../../types/analytics/analyticsEvents.enum'
import { getLayoutType } from '../../helpers/utils'

export const useSendSegmentEvent = <TEventProperties extends AnalyticsEventProperties>() => {
  const { pageContext } = usePageContext()
  const bwllMktSessionId = useBwllMktSessionId()

  const isSegmentTrackingEnabled = useMemo(
    () => String(process.env.SEGMENT_TRACKING_ENABLED).toLowerCase() === 'true',
    [],
  )

  const commonEventProperties = useMemo(
    () => ({
      experiment: pageContext.experiment,
      experiment_treatment_requested: pageContext.treatmentRequested,
      experiment_treatment_selected: pageContext.treatmentSelected,
      bwll_mkt_session_id: bwllMktSessionId,
      page_path: pageContext.pagePath,
      page_url: pageContext.pageUrl,
      layout_type: getLayoutType(),
    }),
    [pageContext, bwllMktSessionId],
  )

  const sendSegmentPageEvent = useCallback(
    (eventProperties: TEventProperties) => {
      if (isSegmentTrackingEnabled && window?.analytics?.page) {
        window.analytics.page({ ...commonEventProperties, ...eventProperties })
      }
    },
    [isSegmentTrackingEnabled, commonEventProperties],
  )

  const sendSegmentTrackEvent = useCallback(
    (eventName: AnalyticsEvents, eventProperties: TEventProperties) => {
      if (isSegmentTrackingEnabled && window?.analytics?.track) {
        window.analytics.track(eventName, { ...commonEventProperties, ...eventProperties })
      }
    },
    [isSegmentTrackingEnabled, commonEventProperties],
  )

  return { sendSegmentPageEvent, sendSegmentTrackEvent }
}
