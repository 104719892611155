import { AnalyticsEvents } from './analyticsEvents.enum'

export enum LayoutTypes {
  MWEB_LAYOUT = 'mweb',
  WEB_LAYOUT = 'web',
}

export type PageViewedEventProperties = {
  entry_id: string
}

export type LinkClickedEventProperties = {
  entry_id: string
  contentful_type: string
  link_to: string
  link_text: string
}

export type ButtonClickedEventProperties = {
  entry_id: string
  contentful_type: string
  button_text: string
}

export type AnalyticsEventProperties =
  | PageViewedEventProperties
  | LinkClickedEventProperties
  | ButtonClickedEventProperties

export type GTMEventProperties<TProperties extends AnalyticsEventProperties> = TProperties & { event: AnalyticsEvents }
