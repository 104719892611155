import { LayoutTypes } from '../types/analytics/analyticsEventProperties'
import { ILink } from '../graphql/modules/link'
import { ILinkCookieData } from '../graphql/modules/linkCookieData'
import get from 'lodash/get'

interface ISortByDateObj {
  date: string
  [x: string]: any
}

export enum LogLevel {
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error',
  INFO = 'info',
}

/**
 * https://www.gatsbyjs.org/docs/environment-variables/
 */
export function isDevelopment() {
  return process.env.NODE_ENV === 'development'
}

/**
 * @description a function that logs to console only when env is set to development
 * @param {string} message
 * @param {LogLevel} logLevel
 * @returns {void}
 */
export const logger = (message: string, logLevel: LogLevel = LogLevel.LOG) =>
  isDevelopment() && console[logLevel](message)

/** *
 * This function is for sorting objects that contain a date property.
 */
export function sortByDate(itemOne: ISortByDateObj, itemTwo: ISortByDateObj, sortField = 'date') {
  const itemOneDate = get(itemOne, sortField, '')
  const itemTwoDate = get(itemTwo, sortField, '')
  const itemOneDateObject = itemOneDate ? new Date(itemOneDate) : new Date()
  const itemTwoDateObject = itemTwoDate ? new Date(itemTwoDate) : new Date()

  return itemOneDateObject.getTime() - itemTwoDateObject.getTime()
}

export function formatSectionId(title: string | null) {
  if (typeof title !== 'string') return null
  const id = title
    .replace(/[^0-9a-z ]/gi, '')
    .trim()
    .replace(/  +/g, ' ')
    .replace(/ /g, '-')
    .toLowerCase()
  if (id.length === 0) return null
  return id
}

// This is used to detect if the code is running at build time (SSG) or in the browser.
export function getIsSsg() {
  return !(typeof window !== 'undefined' && window.document && window.document.createElement)
}

export function isProductionEnvironment() {
  return window.location.origin === process.env.WEBSITE_HOST && window.location.origin.includes('borrowell.com')
}

export function getCookieData(link?: ILink): ILinkCookieData | null {
  if (
    link?.callToAction === true &&
    link?.cookieKey !== null &&
    link?.cookieKey !== undefined &&
    link?.cookieValue !== null &&
    link?.cookieValue !== undefined
  ) {
    return {
      cookieKey: link.cookieKey,
      cookieValue: link.cookieValue,
    }
  }

  return null
}

export function getLayoutType(): string {
  // Return empty layout type when in SSG mode
  if (getIsSsg()) {
    return ''
  }

  return window.innerWidth < 599 ? LayoutTypes.MWEB_LAYOUT : LayoutTypes.WEB_LAYOUT
}
