import { HTTP_PREFIX } from '../constants'
import { paramsService } from '../services/paramsService'
import { MARKETING_SESSION_ID_COOKIE_KEY } from '../services/CookiesService'

export const isExternalLink = (url: string): boolean => url.includes(HTTP_PREFIX)

/**
 * @todo for future uses, open third party links in a new tab to reduce the bounce rate
 */
export const isHelpCentreLink = (url: string): boolean => {
  const BW_HELP_CENTRE_REGEX = /helpcentre.borrowell.com/g
  return BW_HELP_CENTRE_REGEX.test(url)
}

export const isThirdPartyLink = (url: string): boolean => {
  const BW_REGEX = /borrowell.com/g
  return isExternalLink(url) && !BW_REGEX.test(url)
}

export const isWebAppLink = (url: string): boolean => {
  const BW_APP_REGEX = /secure.borrowell.com/g
  return BW_APP_REGEX.test(url)
}

export const isEmailLink = (url: string): boolean => {
  const EMAIL_REGEX = /mailto:/g
  return EMAIL_REGEX.test(url)
}

export const isTelLink = (url: string): boolean => {
  const TEL_REGEX = /tel:/g
  return TEL_REGEX.test(url)
}

export const isInternalNavigationLink = (url: string) =>
  !(isExternalLink(url) || isWebAppLink(url) || isEmailLink(url) || isTelLink(url))

/**
 * @description a function to transform a url string (with or w/o params) into a hashmap of param values
 * @param {string} url
 * @returns {object} a hashmap with all the queryParams
 * @example 'param1=value1&param2=value2' => { param1: 'value1', param2: 'value2' }
 */
export const extractParamsFromUrl = (url: string): Record<string, string> => {
  if (!url || typeof url !== 'string') {
    return {}
  }
  return (
    url
      // Splitting the url in 2 pieces ['https://urlRoot.com?', 'param1=value1&param2=value2']
      .split('?')
      // Getting the last value of the Array (to make sure we only get the params, allowing the function to deal with both cases)
      .slice(-1)[0]
      // Splitting all the params
      .split('&')
      // Transforming the string into a hashmap of query params (this also de-dupes the keys automatically)
      .reduce((acc, param) => {
        const [key, value] = param.split('=')
        if (key && value) {
          acc[key] = value
        }
        return acc
      }, {} as Record<string, string>)
  )
}

export const paramsObjectToString = (params: any) => {
  return Object.entries(params).reduce((prev, [key, value]) => {
    if (!value) return prev
    return prev ? `${prev}&${key}=${value}` : `${key}=${value}`
  }, '')
}
