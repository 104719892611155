import styled from '@emotion/styled'
import React from 'react'

import { HEADER_HEIGHT_VALUES } from '../constants'
import HeaderSection from '../containers/HeaderSection'
import mq from '../styles/breakpoints'
import SnackBar from './SnackBar'

const LayoutWrapper = styled.div`
  margin: auto;
`
const HeaderSaveArea = styled.div`
  ${mq({
    height: [
      `${HEADER_HEIGHT_VALUES.MOBILE}px`,
      `${HEADER_HEIGHT_VALUES.TABLET}px`,
      `${HEADER_HEIGHT_VALUES.DESKTOP}px`,
    ],
  })}
`

const Layout: React.FC = ({ children }) => {
  return (
    <LayoutWrapper>
      <HeaderSection />
      <SnackBar />
      <HeaderSaveArea />
      {children}
    </LayoutWrapper>
  )
}

export default Layout
