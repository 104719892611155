import React from 'react'

const getPosition = () => ({
  x: window.pageXOffset,
  y: window.pageYOffset,
})

type UseScrollValue = [boolean, number]

const useScrollValue = (): UseScrollValue => {
  const scrollValue = React.useRef(0)
  const [isScrolled, setIsScrolled] = React.useState(false)

  React.useEffect(() => {
    const handleScroll = () => {
      const eventScrollValue = getPosition().y
      scrollValue.current = eventScrollValue

      if (isScrolled && eventScrollValue === 0) {
        setIsScrolled(false)
      } else if (!isScrolled && eventScrollValue > 0) {
        setIsScrolled(true)
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isScrolled])

  return [isScrolled, scrollValue.current]
}

export default useScrollValue
