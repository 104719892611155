import { v4 as uuid } from 'uuid'

import {
  AbstractCookieService,
  cookieService,
  MARKETING_SESSION_ID_COOKIE_KEY,
  MARKETING_UID_COOKIE_KEY,
} from './CookiesService'
import { VISIT_URL } from '../constants'

import { extractParamsFromUrl } from '../helpers/urlFunctions'

type GUID_KEY = typeof MARKETING_SESSION_ID_COOKIE_KEY | typeof MARKETING_UID_COOKIE_KEY

export class GuidService {
  cookieService: AbstractCookieService
  guid: {
    [MARKETING_SESSION_ID_COOKIE_KEY]: string
    [MARKETING_UID_COOKIE_KEY]: string
  }

  constructor(cookieService: AbstractCookieService) {
    this.cookieService = cookieService
    this.guid = {
      [MARKETING_SESSION_ID_COOKIE_KEY]: this.getOrCreateGuid(MARKETING_SESSION_ID_COOKIE_KEY),
      [MARKETING_UID_COOKIE_KEY]: this.getOrCreateGuid(MARKETING_UID_COOKIE_KEY),
    }
  }

  getStoredGuid(key: GUID_KEY) {
    return this.guid[key]
  }

  static getNewGuid() {
    return uuid()
  }

  private getOrCreateGuid(key: GUID_KEY): string {
    const visitUrlCookie = this.cookieService.getCookie(VISIT_URL)

    if (visitUrlCookie) {
      const visitUrlParams = extractParamsFromUrl(visitUrlCookie)
      const visitUrlValue = visitUrlParams[key]
      if (visitUrlValue) return visitUrlValue
    }

    const currentCookieValue = this.cookieService.getCookie(key)
    if (typeof currentCookieValue !== 'undefined' && currentCookieValue !== 'undefined') {
      return currentCookieValue
    }

    const newGuid = GuidService.getNewGuid()
    this.cookieService.setCookie({
      key,
      value: newGuid,
      cookieAttributes: key === MARKETING_UID_COOKIE_KEY ? { expires: 365 } : undefined,
    })
    return newGuid
  }
}

export const guidService = new GuidService(cookieService)
