// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-page-adapter-blog-tsx": () => import("./../../../src/containers/PageAdapterBlog.tsx" /* webpackChunkName: "component---src-containers-page-adapter-blog-tsx" */),
  "component---src-containers-page-adapter-tsx": () => import("./../../../src/containers/PageAdapter.tsx" /* webpackChunkName: "component---src-containers-page-adapter-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-archive-tsx": () => import("./../../../src/pages/blog/archive.tsx" /* webpackChunkName: "component---src-pages-blog-archive-tsx" */)
}

